import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

function ThreatsProfile() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Preventative Barrier Status",
      },
    },
  };

  const { user } = useSelector((state) => state.auth);
  const [dashboard, setDashboard] = useState();

  useEffect(() => {
    fetchThreatData();
    // console.info("fetching threat data...")
    // eslint-disable-next-line
  }, []);

  const fetchThreatData = async () => {
    const { threatsProfile } = await fetch("/api/dashboard", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
    }).then((r) => r.json());
    // console.info("threatsProfile: ", threatsProfile)
    setDashboard({ threatsProfile });
    // console.info("setting threats to dashboard data...")
  };

  if (dashboard) {
    // console.info("dashboard data is not null...")
    // console.info("dashboard data: ", dashboard)
    const labels = dashboard.threatsProfile.map((threat) => threat.threatTitle);

    const data = {
      labels,
      datasets: [
        {
          label: "Likelihood",
          data: dashboard.threatsProfile.map((threat) => threat.likelihood),
          backgroundColor: "#6495ED",
          // backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Prevented Likelihood",
          data: dashboard.threatsProfile.map((threat) => threat.liveLikelihood),
          backgroundColor: "#B0CDFF",
          // backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    };
    return <Bar options={options} data={data} />;
  }
}

export default ThreatsProfile;
