import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateBarrier, deleteBarrier } from "../features/bowties/barrierSlice";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";

function EditThreatBarrierModal(props) {
  const { bowtie } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [aiButton, setAiButton] = useState(true);
  const [titleError, setTitleError] = useState(false);

  const bowtieBarriers = useSelector((state) =>
    state.barriers.barriers.filter((barrier) => barrier.bowtie === bowtie)
  );

  let thisBarrier = bowtieBarriers.find(
    (barrier) => barrier._id === props.threatBarrierId
  );

  const initialBarriers = bowtieBarriers.filter(
    (barrier) => barrier.threat === thisBarrier.threat
  );

  const [editedThreatBarrier, setEditedThreatBarrier] = useState(thisBarrier);
  const [barriersForSelectedThreat, setBarriersForSelectedThreat] =
    useState(initialBarriers);
  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    editedThreatBarrier.description === ""
      ? setAiButton(true)
      : setAiButton(false);
  }, [editedThreatBarrier.description]);

  useEffect(() => {
    if (editedThreatBarrier.threat) {
      const newBarriers = bowtieBarriers.filter(
        (barrier) => barrier.threat === editedThreatBarrier.threat
      );
      setBarriersForSelectedThreat(newBarriers);
    }
  }, [editedThreatBarrier.threat, bowtieBarriers]);

  const handleClose = () => {
    props.setOpenThreatBarrierModal(false);
    thisBarrier = "";
    setEditedThreatBarrier({});
    props.setThreatBarrierId("");
    setAiButton(true);
    setBarriersForSelectedThreat([]);
    positionLength = 0;
    setTitleError(false);
    setIsEdited(false);
  };

  const threats = useSelector((state) =>
    state.threats.threats.filter((threat) => threat.bowtie === bowtie)
  );

  const threatSelect = threats.map((threat) => (
    <MenuItem value={threat._id} key={threat._id}>
      {threat.title}
    </MenuItem>
  ));

  let positionLength = barriersForSelectedThreat.length;

  const positionsSelect =
    positionLength === 0 ? (
      [<MenuItem value={1} key={1}>
        1
      </MenuItem>]
    ) : (
      [
        ...Array.from({ length: positionLength }, (_, i) => (
          <MenuItem value={i + 1} key={i + 1}>
            {i + 1}
          </MenuItem>
        )),
      ]
    );

  const handleChangeTitle = (e) => {
    setTitleError(false);
    setEditedThreatBarrier({
      ...editedThreatBarrier,
      title: e.target.value,
    });
    setIsEdited(true);
  };

  const handleChangeThreat = (e) => {
    setEditedThreatBarrier({
      ...editedThreatBarrier,
      threat: e.target.value,
      position: "",
    });
    setIsEdited(true);
  };

  const handleChangeDescription = (e) => {
    setEditedThreatBarrier({
      ...editedThreatBarrier,
      description: e.target.value,
    });
    setIsEdited(true);
  };

  const min = 0;
  const max = 100;

  const handleChangeCondition = (e) => {
    const conditionPercentage = Math.max(
      min,
      Math.min(max, Number(e.target.value))
    );
    setEditedThreatBarrier({
      ...editedThreatBarrier,
      condition: conditionPercentage,
    });
    setIsEdited(true);
  };

  const handleChangeQuality = (e) => {
    const qualityPercentage = Math.max(
      min,
      Math.min(max, Number(e.target.value))
    );
    setEditedThreatBarrier({
      ...editedThreatBarrier,
      quality: qualityPercentage,
    });
    setIsEdited(true);
  };

  const handleChangePosition = (e) => {
    setEditedThreatBarrier({
      ...editedThreatBarrier,
      position: e.target.value,
    });
    setIsEdited(true);
  };

  const generateThreatBarrierTitle = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    setAiButton(true);
    const response = await axios.post(
      "/api/ai/threat-barrier",
      { description: editedThreatBarrier.description },
      config
    );
    setAiButton(false);
    //fake an onChange event from the DOM to piggy back the handler, so as to avoid invoking a controlled input by setting its value
    const newObj = { target: { value: response.data } };
    handleChangeTitle(newObj);
  };

  const moveBarrier = (barriers, editedBarrier, previousBarrier) => {
    return barriers
      .map((barrier) => {
        if (barrier._id === editedBarrier._id) {
          // Skip the edited barrier itself
          return null;
        } else if (
          editedBarrier.position < previousBarrier.position &&
          barrier.position >= editedBarrier.position &&
          barrier.position < previousBarrier.position
        ) {
          // Moving up: shift barriers down
          return { ...barrier, position: barrier.position + 1 };
        } else if (
          editedBarrier.position > previousBarrier.position &&
          barrier.position <= editedBarrier.position &&
          barrier.position > previousBarrier.position
        ) {
          // Moving down: shift barriers up
          return { ...barrier, position: barrier.position - 1 };
        } else if (barrier.position === previousBarrier.position) {
          // Handle the case where barrier.position === previousBarrier.position
          return { ...barrier, position: barrier.position + 1 };
        } else {
          // No change needed
          return barrier;
        }
      })
      .filter((barrier) => barrier !== null);
  };

  const insertBarrier = (barriers, editedBarrier) => {
    //map over barriers
    return barriers
      .map((barrier) => {
        //if barrier position is less than editedBarrier position, do nothing
        if (barrier.position < editedBarrier.position) {
          return null;
          //else if barrier position is equal to or greater than editedBarrier position, increment position by 1
        } else if (barrier.position >= editedBarrier.position) {
          return { ...barrier, position: barrier.position + 1 };
        } else {
          return null;
        }
      })
      .filter((barrier) => barrier !== null);
  };

  const handleSave = () => {
    // Clean up
    setTitleError(false);

    if (editedThreatBarrier.title === "") {
      setTitleError(true);
    } else {
      let previousThreatBarrier = bowtieBarriers.find(
        (barrier) => barrier._id === editedThreatBarrier._id
      );

      if (editedThreatBarrier.threat !== previousThreatBarrier.threat) {
        // Threat has changed
        // Shuffle positions for the new threat
        let newThreatBarriers = bowtieBarriers.filter(
          (barrier) => barrier.threat === editedThreatBarrier.threat
        );
        let updatedNewThreatBarriers = insertBarrier(
          newThreatBarriers,
          editedThreatBarrier
        );

        // Shuffle positions for the old threat
        let oldThreatBarriers = bowtieBarriers.filter(
          (barrier) => barrier.threat === previousThreatBarrier.threat
        );
        let remainingOldThreatBarriers = oldThreatBarriers.filter(
          (barrier) => barrier._id !== editedThreatBarrier._id
        );

        // Sort the old barriers by position, given that a barrier has been removed
        const sortedBarriers = remainingOldThreatBarriers.sort(
          (a, b) => a.position - b.position
        );

        // Reassign the position values to be contiguous
        const sortedRemainingOldThreatBarriers = sortedBarriers.map(
          (barrier, index) => ({
            ...barrier,
            position: index + 1,
          })
        );

        // Dispatch updates for both new and old threat barriers
        updatedNewThreatBarriers.forEach((barrier) => {
          dispatch(updateBarrier(barrier));
        });
        sortedRemainingOldThreatBarriers.forEach((barrier) => {
          dispatch(updateBarrier(barrier));
        });
      } else {
        // Threat has not changed
        let updatedBarriers = moveBarrier(
          barriersForSelectedThreat,
          editedThreatBarrier,
          previousThreatBarrier
        );

        // Dispatch updates for the current threat barriers
        updatedBarriers.forEach((barrier) => {
          dispatch(updateBarrier(barrier));
        });
      }

      // Dispatch the update for the edited barrier
      dispatch(updateBarrier(editedThreatBarrier));
      handleClose();
    }
  };

  const handleDelete = () => {
    // Step 1: Filter out the deleted barrier
    const remainingBarriers = barriersForSelectedThreat.filter(
      (barrier) => barrier._id !== editedThreatBarrier._id
    );

    // Step 2: Sort the remaining barriers by position
    const sortedBarriers = remainingBarriers.sort(
      (a, b) => a.position - b.position
    );

    // Step 3: Reassign the position values to be contiguous
    const updatedBarriers = sortedBarriers.map((barrier, index) => ({
      ...barrier,
      position: index + 1,
    }));

    // Step 4: Dispatch the updated barriers to the Redux store
    updatedBarriers.forEach((barrier) => {
      dispatch(updateBarrier(barrier));
    });

    // Dispatch the delete action for the barrier
    dispatch(deleteBarrier(editedThreatBarrier._id));
    handleClose();
  };

  return (
    <Dialog open={props.openThreatBarrierModal} onClose={handleClose}>
      <DialogTitle>
        {editedThreatBarrier.title}
        <HealthAndSafetyIcon
          sx={{ color: "#FFD700", position: "absolute", top: 20, right: 20 }}
        />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <TextField
              autoFocus
              margin="dense"
              id={"title" + props.threatBarrierId}
              label="Title"
              fullWidth
              required
              error={titleError}
              helperText={titleError ? "required" : ""}
              name="title"
              variant="outlined"
              placeholder={"Please provide a title for this barrier"}
              onChange={handleChangeTitle}
              value={editedThreatBarrier.title}
            />
          </Grid>
          <Grid item xs={2} alignItems="stretch" style={{ display: "flex" }}>
            <Tooltip
              title={
                !aiButton
                  ? "use AI 🤖 to convert your description into a concise summarised title"
                  : ""
              }
            >
              <Button
                variant="contained"
                name="generate ai summary title"
                color="secondary"
                onClick={generateThreatBarrierTitle}
                disabled={aiButton}
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  minWidth: "100%",
                  minHeight: "100%",
                }}
              >
                <PsychologyOutlinedIcon />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              id={"description" + props.threatBarrierId}
              label="Description"
              fullWidth
              multiline
              rows={4}
              name="description"
              variant="outlined"
              onChange={handleChangeDescription}
              placeholder={"Please provide a description of this barrier"}
              value={editedThreatBarrier.description}
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <FormControl fullWidth>
              <InputLabel id="threat-select-label">Threat</InputLabel>
              <Select
                labelId="threat-select-id-label"
                id="threat-select"
                value={editedThreatBarrier.threat}
                label="Threat"
                name="threat"
                onChange={handleChangeThreat}
              >
                {threatSelect}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} mt={1.5}>
            <TextField
              label="Control Quality"
              value={editedThreatBarrier.quality}
              id="control-quality"
              type="number"
              name="quality"
              fullWidth
              placeholder={"How reliable is this barrier?"}
              onChange={handleChangeQuality}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={6} mt={1.5}>
            <TextField
              label="Control Condition"
              id="control-condition"
              type="number"
              fullWidth
              name="condition"
              placeholder={"Barrier condition right now?"}
              onChange={handleChangeCondition}
              value={editedThreatBarrier.condition}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} mt={1}>
            <FormControl required fullWidth>
              <InputLabel id="position-label" >Position</InputLabel>
              <Select
                labelId="position-label"
                id="position"
                value={editedThreatBarrier.position}
                label="Position"
                name="position"
                onChange={handleChangePosition}
              >
                {positionsSelect}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          disabled={!isEdited}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditThreatBarrierModal;
