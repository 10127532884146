import React, { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";

const STRIPE_PUBLISHABLE_KEY="pk_live_9HS7tsS4NDZ3ZlXQnKzcX1FD00OmHrh73q"
//const STRIPE_PUBLISHABLE_KEY = "pk_test_yvfcckKmeySobTz7x6hb06vR00o42TupVn";
//console.log("STRIPE_PUBLISHABLE_KEY", STRIPE_PUBLISHABLE_KEY);
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
//console.log("stripePromise", stripePromise);

const CheckoutForm = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  //console.log("user", user);

  const fetchClientSecret = useCallback(() => {
    const priceId = location.state?.priceId;
    //console.log("priceId", priceId);

    // Create a Checkout Session
    return fetch("/api/payments/create-checkout-session", {
      method: "POST",
      //body needs to contain the priceId
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.token}`,
      },
      body: JSON.stringify({ priceId: priceId }),
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = { fetchClientSecret };

  console.log("options", options);

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
};

export default CheckoutForm;
