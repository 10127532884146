import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getBowties } from "../features/bowties/bowtieSlice";
import { getSeverities } from "../features/bowties/severitySlice";
import { reset } from "../features/auth/authSlice";

const columns = [
  { id: "Hazard-title", label: "Hazard", minWidth: 180 },
  { id: "Event", label: "Event", minWidth: 180 },
  { id: "Risk", label: "Inherent Risk", minWidth: 180 },
  { id: "Dynamic Risk", label: "Dynamic Risk", minWidth: 180 },
  { id: "edit", label: "", minWidth: 100 },
];

export default function HazardsTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { bowties } = useSelector((state) => state.bowties);
  const [tableData, setTableData] = useState();

  useEffect(() => {
    if (bowties.length === 0) {
      dispatch(getBowties());
      dispatch(getSeverities());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    return () => {
      dispatch(reset());
    };
  }, [user, dispatch, navigate]);

  useEffect(() => {
    fetchTableData();
    // eslint-disable-next-line
  }, []);

  const fetchTableData = async () => {
    const { threatsProfile, consequencesProfile } = await fetch(
      "/api/dashboard",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    ).then((r) => r.json());

    setTableData({
      threatsProfile,
      consequencesProfile,
    });
  };

  const bowtieRisk = (bowtieId) => {
    try {
      const bowtieLikelihood = tableData.threatsProfile
        .filter((threat) => {
          return threat.bowtie === bowtieId;
        })
        .reduce((acc, threat) => {
          return acc + threat.likelihood;
        }, 0);
      const bowtieConsequence = tableData.consequencesProfile
        .filter((consequence) => {
          return consequence.bowtie === bowtieId;
        })
        .reduce((acc, consequence) => {
          return acc + consequence.severity;
        }, 0);
      return bowtieLikelihood * bowtieConsequence;
    } catch (error) {
      console.log(error);
    }
  };

  const dynamicRisk = (bowtieId) => {
    try {
      const liveLikelihoods = tableData.threatsProfile
        .filter((threat) => {
          return threat.bowtie === bowtieId;
        })
        .reduce((acc, threat) => {
          return acc + threat.liveLikelihood;
        }, 0);
      const liveRisk = tableData.consequencesProfile
        .filter((consequence) => {
          return consequence.bowtie === bowtieId;
        })
        .reduce((acc, consequence) => {
          return (
            acc +
            (consequence.barriersEffectiveness === 100 ? 1 : (1 - consequence.barriersEffectiveness / 100)) *
              consequence.severity *
              liveLikelihoods
          );
        }, 0);
      return Math.round(liveRisk);
    } catch (error) {
      console.log(error);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "100%" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {bowties
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((bowtie) => {
                  return (
                    <TableRow key={bowtie._id}>
                      <TableCell key={"title" + bowtie._id} align={"left"}>
                        {bowtie.title}
                      </TableCell>
                      <TableCell key={"event" + bowtie._id} align={"left"}>
                        {bowtie.event}
                      </TableCell>
                      <TableCell key={"risk" + bowtie._id} align={"left"}>
                        {bowtieRisk(bowtie._id)}
                      </TableCell>
                      <TableCell
                        key={"dynamic-risl" + bowtie._id}
                        align={"left"}
                      >
                        {dynamicRisk(bowtie._id)}
                      </TableCell>
                      <TableCell key={"edit" + bowtie._id} align={"right"}>
                        <Button
                          variant="contained"
                          onClick={() =>
                            navigate("/editor/" + bowtie._id.toString())
                          }
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={bowties.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
