import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Editor from "./pages/Editor";
import Viewer from "./pages/Viewer"
import Register from "./pages/Register";
import Pricing from "./pages/Pricing";
import Payment from "./pages/Payment";
import Return from "./pages/Return";
import Subscribe from "./pages/Subscribe";
import Account from "./pages/Account";
import ForgotPassword from "./pages/Forgot-Password";
import ResetPassword from "./pages/Reset-Password";
import Error from "./pages/Error"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BowtieTablePage from "./pages/Bowtie-Table";
import ReactGA from 'react-ga4'

function App() {

  ReactGA.initialize("G-RLLXRT2D7W");
  ReactGA.send({ hitType: "pageview", page: "/app-js" });

  return (
    <>
      <Router>
        <div className="container">
          <Header></Header>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/editor/:bowtie" element={<Editor />} />
            <Route path="/viewer/:bowtie" element={<Viewer />} />
            <Route path="/bowtie-table/:bowtie" element={<BowtieTablePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/return" element={<Return />} />
            <Route path="/account" element={<Account />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:id/:token" element={<ResetPassword />}/>
            <Route path="*" element={<Error />}/>
          </Routes>
        </div>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
